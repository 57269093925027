import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import get from 'lodash/get';
import Layout from '../../components/layout';
import Project from '../../components/project';
import { LanguageContext } from '../../context';

function ProjectTemplate(props) {
  const project = get(props, 'data.contentfulProject');
  const location = get(props, 'location');
  return (
    <Layout location={location}>
      <LanguageContext.Consumer>
        {(localeFromReactContext) => {
          return (
            <>
              <Helmet title={project.titre} />
              <Project data={project} locale={localeFromReactContext} />
            </>
          );
        }}
      </LanguageContext.Consumer>
    </Layout>
  );
}

export default ProjectTemplate;

export const pageQuery = graphql`
  query Project($slug: String) {
    contentfulProject(slug: { eq: $slug }) {
      node_locale
      titre
      type
      dateDeFin
      dateDeDebut
      slug
      lieu
      natureDeLoperation
      maitriseDouvrage
      equipeDeMoe
      statutDuProjet
      typeDeMarche
      missionRole
      surfaces
      montantDeLoperation
      imageDePresentation {
        id
        description
        fluid {
          ...GatsbyContentfulFluid
        }
        file {
          details {
            size
            image {
              height
              width
            }
          }
        }
      }
      imagesDuProjet {
        id
        description
        fluid {
          ...GatsbyContentfulFluid
        }
        file {
          details {
            size
            image {
              height
              width
            }
          }
        }
      }
    }
  }
`;
