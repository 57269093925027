import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import moment from 'moment';
import get from 'lodash/get';
import Img from 'gatsby-image';
import SubFilter from '../projectSubfilter';
import useMediaQuery from '../../hooks/useMediaQuery';
import { ProjectTypeNavigationChangeContext } from '../../context';
import styles from './project.module.css';

function RenderDate({ dateDeDebut, dateDeFin }) {
  return dateDeFin ? (
    <span>
      {dateDeDebut} / {dateDeFin}
    </span>
  ) : (
    <span>{dateDeDebut}</span>
  );
}
RenderDate.propTypes = {
  dateDeDebut: PropTypes.number.isRequired,
  dateDeFin: PropTypes.number,
};
RenderDate.defaultProps = {
  dateDeFin: null,
};

function Project(props) {
  const project = get(props, 'data');
  const locale = get(props, 'locale');
  const isFrench = locale === 'fr';
  const imagesDuProjet = get(project, 'imagesDuProjet') || [];
  const onChangeProjectType = useContext(ProjectTypeNavigationChangeContext);
  const isMobile = useMediaQuery('(max-width:480px)');
  function setType(type) {
    onChangeProjectType(type);
    navigate(`/${isFrench ? 'projets' : 'projects'}`);
  }
  return (
    <>
      {!isMobile && <SubFilter currentType={project.type} setType={setType} locale={locale} />}
      <div className={styles.wrapper}>
        <h1 className={styles.h1}>
          <span>{project.titre}</span>
          <RenderDate
            dateDeDebut={moment(project.dateDeDebut).year()}
            dateDeFin={project.dateDeFin ? moment(project.dateDeFin).year() : null}
          />
        </h1>
        <Img alt={project.imageDePresentation.id} fluid={project.imageDePresentation.fluid} />
        <ul className={styles.list}>
          <li>
            <span>{isFrench ? 'Lieu :' : 'Location:'}</span>
            <span>{project.lieu}</span>
          </li>
          <li>
            <span>{isFrench ? "Nature de l'opération :" : 'Type of operation:'}</span>
            <span>{project.natureDeLoperation}</span>
          </li>
          <li>
            <span>{isFrench ? "Maîtrise d'ouvrage :" : 'Project owner:'}</span>
            <span>{project.maitriseDouvrage}</span>
          </li>
          <li>
            <span>{isFrench ? 'Équipe de MOE :' : 'Project management:'}</span>
            <span>{project.equipeDeMoe}</span>
          </li>
          <li>
            <span>{isFrench ? 'Statut du projet :' : 'Project status:'}</span>
            <span>{project.statutDuProjet}</span>
          </li>
          <li>
            <span>{isFrench ? 'Type de marché :' : 'Contract type:'}</span>
            <span>{project.typeDeMarche}</span>
          </li>
          <li>
            <span>{isFrench ? 'Mission / Rôle :' : 'Mission / Role:'}</span>
            <span>{project.missionRole}</span>
          </li>
          <li>
            <span>{isFrench ? 'Surfaces :' : 'Surfaces:'}</span>
            <span>{project.surfaces}</span>
          </li>
          <li>
            <span>{isFrench ? "Montant de l'opération :" : 'Operation amount:'}</span>
            <span>{project.montantDeLoperation}</span>
          </li>
        </ul>
        <hr className={styles.hr} />
        <div>
          {imagesDuProjet.map((image) => (
            <Img className={styles.imagesDuProjet} key={image.id} alt={image.id} fluid={image.fluid} />
          ))}
        </div>
      </div>
    </>
  );
}

export default Project;
